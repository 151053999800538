import {FormattedMessage} from "gatsby-plugin-intl"
import React from "react"
import Image from "../components/image"
import "./reference.scss"

export default ({id, logo}) => {
    return (
        <div className="col-12 col-lg-6 mb-4 reference">
            <div className="d-flex">
                <div className="d-flex flex-row logo bg-secondary flex-grow-0 p-2">
                    <div className="my-auto w-100">
                        <Image which={"logoReference" + logo.charAt(0).toUpperCase() + logo.slice(1)}/>
                    </div>
                </div>
                <div className="ml-4 mt-2 flex-grow-1">
                    <h3><FormattedMessage id={"reference.item." + id + ".title"}/></h3>
                    <p className="text-body small">
                        <FormattedMessage id={"reference.item." + id + ".text"} values={{
                            a: (...chunks) => (
                                <a target="_blank" href={chunks}>
                                    <FormattedMessage id={chunks}/>
                                </a>
                            ),
                            inovak: (...chunks)  => (
                                <a target="_blank" href="/srovnejsito.pdf">
                                    <FormattedMessage id={chunks}/>
                                </a>
                            ),
                        }}
                        /></p>
                </div>
            </div>
        </div>
    )
}
