import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import "./solution.scss"

import IconDev from "-!svg-react-loader!../images/solution/undraw_Mobile_application_mr4r.svg"
import IconEcommerce from "-!svg-react-loader!../images/solution/undraw_add_to_cart_vkjp.svg"
import IconAi from "-!svg-react-loader!../images/solution/undraw_all_the_data_h4ki.svg"
import IconOps from "-!svg-react-loader!../images/solution/undraw_feeling_proud_qne1.svg"

const icons = {
  app: IconDev,
  ecommerce: IconEcommerce,
  ai: IconAi,
  ops: IconOps,
}

const Solution = ({ id, link }) => {
  const Icon = icons[id]
  return (
    <div className="col-12 col-md-6 solution">
      {/*<Link to={link}>*/}
      <span>
        <Icon className="icon"/>
        <h3><FormattedMessage id={"solution." + id + ".title"}/></h3>
        <p className="text-muted"><FormattedMessage id={"solution." + id + ".text"}/></p>
      </span>
      {/*</Link>*/}
    </div>
  )
}

export default Solution
