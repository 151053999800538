import React from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Solution from "../components/solution"
import Reference from "../components/reference"
import HeroSvg from "-!svg-react-loader!../images/hero.svg"
import "./index.scss"

const cities = [
  {
    name: "prague",
    items: ["hq", "sales"],
    link: "https://goo.gl/maps/RSLhtVwkn1aq2zYV6",
  },
  {
    name: "brno",
    items: ["sales", "dev"],
  },
  {
    name: "hradec",
    items: ["sales", "dev"],
    link: "https://goo.gl/maps/pMt75ZWCpNdBLYY57",
  },
  // {
  //   name: "pardubice",
  //   items: ["office", "sales", "dev"],
  //   link: "https://goo.gl/maps/7J28DQLwsFfxr81k7",
  // },
];

const IndexPage = () => {
  const intl = useIntl();

  const CityTitle = ({ city }) => (
    <h5><i className="las la-map-marker mr-2"/><FormattedMessage id={"office.city." + city.name}/></h5>
  );

  const CityTitleLink = ({ city }) => {
    if (city.link) {
      return (<a href={city.link} target="_blank" rel="noopener noreferrer"><CityTitle city={city}/></a>)
    }
    return (<CityTitle city={city}/>)
  };

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "title" })}
        description={intl.formatMessage({ id: "description" })}
        keywords={intl.formatMessage({ id: "keywords" })}
      />
      <div className="d-block d-lg-flex position-relative p-5 p-lg-0">
        <div className="container d-block d-lg-flex align-items-lg-center hero">
          <div className="row w-100">
            <div className="col-lg-5">
              <div className="heading display-4 mb-3">
                <FormattedMessage id="hero.title"/>
              </div>
              <p>
                <FormattedMessage id="hero.welcome" values={{
                  b: (...chunks) => (
                    <strong className="text-primary">{chunks}</strong>
                  ),
                }}/>
              </p>

              <div className="d-flex align-items-center flex-wrap">
                <Link className="btn btn-primary" to="/contact/">
                  <FormattedMessage id="hero.get"/>
                </Link>
                {/*<Link className="btn btn-link ml-3" to="/#reference">*/}
                {/*  <FormattedMessage id="hero.more"/>*/}
                {/*</Link>*/}
              </div>
            </div>
          </div>

          <div id="SVGMainHero" className="col-lg-9 col-xl-7 d-none d-lg-block hero-img">
            <HeroSvg/>
          </div>
        </div>
      </div>

      <div className="pt-4 pb-2 bg-secondary" id="services">
        <div className="container">
          <div className="row row-eq-height">
            <div className="col-12 col-lg-4">
              <h2 className="text-primary mt-3 mb-4 mb-lg-5">
                <FormattedMessage id="solutions.title"/>
              </h2>
              <p className="lead">
                <FormattedMessage id="solutions.text"/>
              </p>
            </div>
            <div className="col-12 col-lg-8 pt-4 pt-lg-0">
              <div className="row row-eq-height">
                <Solution id="ai" link="/artificial-intelligence"/>
                <Solution id="ops" link="/dev-ops-and-automation"/>
                <Solution id="ecommerce" link="/ecommerce-development"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5">
        <div className="container">
          <div className="row row-eq-height">
            <div className="col-12 col-lg-7 d-flex flex-column justify-content-center">
              <div className="pr-lg-4">
                <Image which="photoWindowImage"/>
              </div>
              <div className="row">
                {cities.map(city =>
                  <div className="col mt-4">
                    <CityTitleLink city={city}/>
                    <ul className="small la-ul ml-4">
                      {city.items.map(item =>
                        <li className="px-0"><span className="la-li"><i
                          className="las la-check"/></span><FormattedMessage id={"office.item." + item}/></li>,
                      )}
                    </ul>
                  </div>,
                )}
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <h2 className="text-primary mt-3 mb-4 mb-lg-5">
                <FormattedMessage id="office.title"/>
              </h2>
              <p className="lead">
                <FormattedMessage id="office.text"/>
              </p>
              <div className="d-flex align-items-center flex-wrap pt-4">
                {/*<Link className="btn btn-primary" to="/about/">*/}
                {/*  <FormattedMessage id="office.more"/>*/}
                {/*</Link>*/}
                <Link className="btn btn-link ml-3" to="/contact/">
                  <FormattedMessage id="office.jobs"/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light py-5" id="references">
        <div className="container text-center">
          <div className="col-12 py-4">
            <h2 className="text-primary m-0">
              <FormattedMessage id="reference.title"/>
            </h2>
            <p className="lead my-5">
              <FormattedMessage id="reference.text"/>
            </p>
          </div>
        </div>

        <div className="container" id="references">
          <div className="row row-eq-height">
            <Reference id="fullstack.exim" logo="exim"/>
            <Reference id="mobile.tringa" logo="tringa"/>
            <Reference id="ai.webnode-helpdesk" logo="webnode"/>
            <Reference id="ai.honeywell-aviation" logo="honeywell"/>
            <Reference id="ai.honeywell-thermostats" logo="honeywell"/>
            <Reference id="ai.forex" logo="utm"/>
            <Reference id="fullstack.srovnejsito" logo="srovnejsito"/>
            <Reference id="fullstack.poctivezestatku" logo="poctivezestatku"/>
            <Reference id="fullstack.mazanahypoteka" logo="mazanahypoteka"/>
            <Reference id="fullstack.spocitejsito" logo="spocitejsito"/>
          </div>
        </div>
      </div>

    </Layout>
  )
};

export default IndexPage
